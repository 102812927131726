import { errorIcon, infoIcon, successIcon, warningIcon } from "assets/svg";
import { SnackbarContent } from "notistack";
import Button from "@mui/material/Button";
import React from "react";

function capitalizeFirstLetter(str) {
  if (str && str.toString().length > 1) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return str;
}

export const BaseSnackbar = React.forwardRef((props, ref) => {
  const {
    // You have access to notistack props and options 👇🏼
    id,
    message = "",
    title = "",
    actions = [],
    // as well as your own custom props 👇🏼
    allowDownload,
    backgroundColor,
    textColor,
    icon,
    ...other
  } = props;

  return (
    <SnackbarContent ref={ref} role="alert" {...other}>
      <div
        style={{
          backgroundColor: backgroundColor,
          padding: 20,
          borderRadius: 12,
        }}
      >
        <div
          className="snackBarTitle"
          style={{ color: textColor, fontFamily: "roboto", marginBottom: 4 }}
        >
          {capitalizeFirstLetter(title)}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <div style={{ paddingTop: 4, paddingRight: 8 }}>{icon}</div>
          <div style={{ color: textColor, maxWidth: 250 }}>
            <div>{capitalizeFirstLetter(message)}</div>
          </div>
        </div>
        {actions && (
          <div style={{ marginTop: 8, marginLeft: -12, marginBottom: -6 }}>
            {actions.map((a) => {
              const { label = "", onClick } = a || {};
              return (
                <Button
                  key={label}
                  onClick={onClick}
                  style={{ color: textColor, textTransform: "none" }}
                >
                  {a.label}
                </Button>
              );
            })}
          </div>
        )}
      </div>
    </SnackbarContent>
  );
});

export const ErrorSnackbar = React.forwardRef((props, ref) => {
  return (
    <BaseSnackbar
      ref={ref}
      {...props}
      backgroundColor="#FFD0CD"
      textColor="#410E0B"
      icon={errorIcon}
    />
  );
});

export const InfoSnackbar = React.forwardRef((props, ref) => {
  return (
    <BaseSnackbar
      ref={ref}
      backgroundColor="#D2E9DF"
      textColor="#49454F"
      icon={infoIcon}
      {...props}
    />
  );
});

export const WarningSnackbar = React.forwardRef((props, ref) => {
  return (
    <BaseSnackbar
      ref={ref}
      backgroundColor="#FFEDAB"
      textColor="#7C5705"
      icon={warningIcon}
      {...props}
    />
  );
});

export const SuccessSnackbar = React.forwardRef((props, ref) => {
  return (
    <BaseSnackbar
      ref={ref}
      backgroundColor="#D6F1C9"
      textColor="#307111"
      icon={successIcon}
      {...props}
    />
  );
});
