import red from "@mui/material/colors/red";
import green from "@mui/material/colors/green";

const themes = [
  {
    id: "default",
    color: "#05735A",
    source: {
      palette: {
        primary: { main: "#05735A" },
        secondary: {
          main: "#c62828",
        },
        info: {
          main: "#D2E9DF",
          contrastText: "#49454F",
        },
      },
    },
  },
  {
    id: "red",
    color: "#d42b20",
    source: {
      palette: {
        primary: { main: "#d42b20" },
        secondary: {
          main: "#c62828",
        },
        info: {
          main: "#D2E9DF",
        },
      },
    },
  },
  {
    id: "green",
    color: green[500],
    source: {
      palette: {
        primary: green,
        secondary: red,
        error: red,
        info: {
          main: "#D2E9DF",
        },
      },
    },
  },
];

export default themes;
