/* eslint-disable react/jsx-key */
import React, { lazy } from "react";
import UnauthorizedRoute from "base-shell/lib/components/UnauthorizedRoute";
import PageNotFound from "pages/PageNotFound/PageNotFound";
import AuthorizedRoute from "components/AuthorizedRoute";

const SignIn = lazy(() => import("../pages/SignIn/SignIn"));
const Login = lazy(() => import("../pages/auth/login/login"));
const Register = lazy(() => import("../pages/auth/register/register"));
const SetupProfile = lazy(() =>
  import("../pages/auth/setup-profile/setupProfile")
);
const FillAddress = lazy(() =>
  import("../pages/auth/setup-profile/fillAddress")
);
const RegisterComplete = lazy(() =>
  import("../pages/auth/register/registerComplete")
);
const PatientsList = lazy(() => import("../pages/patients/patientsList"));
const PatientsProfile = lazy(() => import("../pages/patients/patientProfile"));
const TestDetails = lazy(() => import("../pages/patients/testDetails"));
const TestDetailsDocs = lazy(() => import("../pages/patients/testDetailsDocs"));
const DoctorProfile = lazy(() => import("../pages/doctor/doctorProfile"));
const AccountSetting = lazy(() => import("../pages/doctor/accountSetting"));
const BlogPosts = lazy(() => import("../pages/Blog/BlogPosts"));
const PostDetails = lazy(() => import("../pages/Blog/PostDetails"));
const ForgetPassword = lazy(() =>
  import("../pages/auth/forget-password/forget-password")
);
const ResetPassword = lazy(() =>
  import("../pages/auth/forget-password/resetPassword")
);
const AccountVerified = lazy(() =>
  import("../pages/auth/login/accountVerified")
);
const VerifyFromMobile = lazy(() =>
  import("../pages/auth/login/verifyFromMobile")
);
const CheckEmail = lazy(() =>
  import("../pages/auth/forget-password/checkEmail")
);
const CheckOtp = lazy(() => import("../pages/auth/forget-password/checkOtp"));

const SignUp = lazy(() => import("../pages/SignUp/SignUp"));
const PasswordReset = lazy(() =>
  import("../pages/PasswordReset/PasswordReset")
);
const About = lazy(() => import("../pages/About"));
const Home = lazy(() => import("../pages/Home/Home"));
const DialogDemo = lazy(() => import("../pages/DialogDemo/DialogDemo"));
const ToastDemo = lazy(() => import("../pages/ToastDemo/ToastDemo"));
const FilterDemo = lazy(() => import("../pages/FilterDemo"));
const ListPageDemo = lazy(() => import("../pages/ListPageDemo"));
const TabsDemo = lazy(() => import("../pages/TabsDemo"));
const MyAccount = lazy(() => import("../pages/MyAccount/MyAccount"));
const DeleteAccount = lazy(() => import("../pages/DeleteAccount"));

const routes = [
  {
    path: "/signin",
    exact: true,
    element: (
      <UnauthorizedRoute>
        <SignIn redirectTo="/home" />
      </UnauthorizedRoute>
    ),
  },
  {
    path: "/login",
    exact: true,
    element: (
      <UnauthorizedRoute>
        <Login redirectTo="/home" />
      </UnauthorizedRoute>
    ),
  },
  {
    path: "/forgetPassword",
    exact: true,
    element: (
      <UnauthorizedRoute>
        <ForgetPassword redirectTo="/home" />
      </UnauthorizedRoute>
    ),
  },
  {
    path: "/resetPassword",
    exact: true,
    element: (
      <UnauthorizedRoute>
        <ResetPassword redirectTo="/home" />
      </UnauthorizedRoute>
    ),
  },
  {
    path: "/checkOtp",
    exact: true,
    element: (
      <UnauthorizedRoute>
        <CheckOtp redirectTo="/home" />
      </UnauthorizedRoute>
    ),
  },
  {
    path: "/CheckEmail",
    exact: true,
    element: (
      <UnauthorizedRoute>
        <CheckEmail redirectTo="/home" />
      </UnauthorizedRoute>
    ),
  },
  {
    path: "/register",
    exact: true,
    element: (
      <UnauthorizedRoute>
        <Register redirectTo="/home" />
      </UnauthorizedRoute>
    ),
  },
  {
    path: "/app/verify-email/:Code",
    exact: true,
    element: <AccountVerified redirectTo="/home" />,
  },
  {
    path: "/app/change_email",
    exact: true,
    element: <VerifyFromMobile redirectTo="/home" />,
  },
  {
    path: "/setupProfile",
    exact: true,
    element: (
      <UnauthorizedRoute>
        <SetupProfile redirectTo="/home" />
      </UnauthorizedRoute>
    ),
  },
  {
    path: "/fillAddress",
    exact: true,
    element: (
      <UnauthorizedRoute>
        <FillAddress redirectTo="/home" />
      </UnauthorizedRoute>
    ),
  },
  {
    path: "/registerComplete",
    exact: true,
    element: (
      <UnauthorizedRoute>
        <RegisterComplete redirectTo="/home" />
      </UnauthorizedRoute>
    ),
  },

  {
    path: "/",
    exact: true,
    element: (
      <AuthorizedRoute>
        <PatientsList />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/patientsList",
    exact: true,
    element: (
      <AuthorizedRoute>
        <PatientsList />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/patientsProfile",
    exact: true,
    element: (
      <AuthorizedRoute>
        <PatientsProfile />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/testDetailsDocs",
    exact: true,
    element: <TestDetailsDocs />,
  },
  {
    path: "/testDetails",
    exact: true,
    element: <TestDetails />,
  },
  {
    path: "/doctorProfile",
    exact: true,
    element: (
      <AuthorizedRoute>
        <DoctorProfile />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/blog",
    exact: true,
    element: (
      <AuthorizedRoute>
        <BlogPosts />
      </AuthorizedRoute>
    ),
  },
  {
    path: "blog/post/:id",
    exact: true,
    element: (
      <AuthorizedRoute>
        <PostDetails />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/accountSetting",
    exact: true,
    element: (
      <AuthorizedRoute>
        <AccountSetting />
      </AuthorizedRoute>
    ),
  },

  {
    path: "/signup",
    exact: true,
    element: (
      <UnauthorizedRoute>
        <SignUp redirectTo="/home" />
      </UnauthorizedRoute>
    ),
  },
  {
    path: "/password_reset",
    exact: true,
    element: (
      <UnauthorizedRoute>
        <PasswordReset redirectTo="/home" />
      </UnauthorizedRoute>
    ),
  },
  {
    path: "/delete_account",
    exact: true,
    element: (
      <UnauthorizedRoute>
        <DeleteAccount redirectTo="/my_account" />
      </UnauthorizedRoute>
    ),
  },
  {
    path: "/app/delete-user-account",
    exact: true,
    element: (
      <UnauthorizedRoute>
        <DeleteAccount redirectTo="/my_account" />
      </UnauthorizedRoute>
    ),
  },
  {
    path: "/app/delete-user-account/:token",
    exact: true,
    element: (
      <UnauthorizedRoute>
        <DeleteAccount redirectTo="/my_account" />
      </UnauthorizedRoute>
    ),
  },
  {
    path: "/about",
    exact: true,
    element: <About />,
  },
  {
    path: "/my_account",
    exact: true,
    element: (
      <AuthorizedRoute>
        <MyAccount />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/home",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Home />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/dialog_demo",
    exact: true,
    element: (
      <UnauthorizedRoute>
        <DialogDemo />
      </UnauthorizedRoute>
    ),
  },
  {
    path: "/toast_demo",
    exact: true,
    element: (
      <AuthorizedRoute>
        <ToastDemo />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/filter_demo",
    exact: true,
    element: (
      <AuthorizedRoute>
        <FilterDemo />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/list_page_demo",
    exact: true,
    element: (
      <AuthorizedRoute>
        <ListPageDemo />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/tabs_demo",
    exact: true,
    element: (
      <AuthorizedRoute>
        <TabsDemo />
      </AuthorizedRoute>
    ),
  },
  {
    path: "*",
    exact: true,
    element: <PageNotFound />,
  },
];

export default routes;
