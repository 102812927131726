import { lazy } from "react";
import locales from "./locales";
import routes from "./routes";
import themes from "./themes";
import parseLanguages from "base-shell/lib/utils/locale";
import Loading from "../components/Loading/Loading";
import {
  ErrorSnackbar,
  InfoSnackbar,
  SuccessSnackbar,
  WarningSnackbar,
} from "components/Snackbars";

const config = {
  containers: {
    LayoutContainer: lazy(() => import("../containers/LayoutContainer")),
  },
  components: {
    Loading,
    //Menu: Menu,
    Menu: lazy(() => import("../containers/Menu")),
  },
  auth: {
    signInURL: "/login",
  },
  pwa: {
    useiOSPWAPrompt: true,
    iOSPWAPromptProps: {},
  },
  routes,
  locale: {
    locales,
    defaultLocale: parseLanguages(["en", "de", "ru"], "en"),
    onError: (e) => {
      // Here we warn the user about translation error
      //console.warn(e)
    },
  },
  menu: {
    width: 388,
    offlineIndicatorHeight: 12,
    initialAuthMenuOpen: false,
    initialMiniMode: false,
    initialMenuOpen: true,
    initialMobileMenuOpen: false,
    initialMiniSwitchVisibility: true,
    MenuHeader: lazy(() => import("../containers/Menu/Header")),
    MenuContent: lazy(() => import("../containers/Menu/Content")),
    useWindowWatcher: false,
  },
  theme: {
    themes,
    defaultThemeID: "default",
    defaultIsDarkMode: false,
    defaultIsRTL: false, //change this to true for default Right to Left Language support
  },
  notistack: {
    Components: {
      error: ErrorSnackbar,
      info: InfoSnackbar,
      warning: WarningSnackbar,
      success: SuccessSnackbar,
    },
  },
  pages: {
    //LandingPage: lazy(() => import("../pages/LandingPage/LandingPage")),

    PageNotFound: lazy(() => import("../pages/PageNotFound/PageNotFound")),
  },
};

export default config;
