import React, { Component } from "react";
import App from "base-shell/lib";
import _config from "./config";
import "react-photo-view/dist/react-photo-view.css";
import "./assets/scss/app.scss";

export default class Demo extends Component {
  render() {
    return <App config={_config} />;
  }
}
